
export enum RequestMethod {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE"
}

export interface MagicRequest {
    url: string;
    method: RequestMethod;
    headers: Headers;
    body?: BodyInit;
    authorization?: string
}
