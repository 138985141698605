import { HTTP_STATUS_CODE } from "~/assets/strings";

export interface ErrorDictionary {
  [key: string]: string;
}

export class HttpError extends Error {
  private httpStatusCode: number;
  private errors?: ErrorDictionary;

  constructor(httpStatusCode: number, errors?: ErrorDictionary) {
    super(HTTP_STATUS_CODE + httpStatusCode);
    this.httpStatusCode = httpStatusCode;
    this.errors = errors;
    Object.setPrototypeOf(this, HttpError.prototype);
  }

  getStatusCode(): number {
    return this.httpStatusCode;
  }

  getErrors(): ErrorDictionary | undefined {
    return this.errors;
  }
}
