import { getRequestEvent, isServer } from 'solid-js/web';

const TEST_DOMAIN = 'hogwarts.with.magicdoor.com';

export const getCurrentDomain = () => {
  if (import.meta.env.DEV) {
    return TEST_DOMAIN;
  }
  if (isServer) {
    const event = getRequestEvent();
    if (event) {
      return event.request.headers.get('host') ?? '';
    }
    throw new Error('No request event found');
  }

  return window.location.hostname;
};
