import { MagicRequest } from './magicRequest';
import { NetworkManager } from './networkManager';

export class BaseNetworkManager implements NetworkManager {
  public sendRequest = async (request: MagicRequest): Promise<Response> => {
    return await fetch(request.url, {
      method: request.method,
      headers: request.headers,
      body: request.body,
    });
  };
}
