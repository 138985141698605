type EventHandler = (data?: any) => void;

export interface EventEmitter {
  registerForStateChange(handler: EventHandler): void;
  unregisterFromStateChange(handler: EventHandler): void;
  registerForNavigation(handler: EventHandler): void;
  unregisterFromNavigation(handler: EventHandler): void;
  registerForErrors(handler: EventHandler): void;
  unregisterFromErrors(handler: EventHandler): void;
  emitStateChange(data?: any): void;
  emitError(error: Error): void;
  emitNavigation(url: string): void;
}

class ConcreteEventEmitter implements EventEmitter {
  private events: { [key: string]: EventHandler[] } = {};
  private stateChange = 'stateChange';
  private navigation = 'navigation';
  private error = 'error';
  private progress = 'progress';
  private state: any;

  public registerForStateChange(handler: EventHandler) {
    this.register(this.stateChange, handler);
    if (this.state !== undefined) {
      handler(this.state);
    }
  }

  public unregisterFromStateChange(handler: EventHandler) {
    this.unregister(this.stateChange, handler);
  }

  public registerForNavigation(handler: EventHandler) {
    this.register(this.navigation, handler);
  }

  public unregisterFromNavigation(handler: EventHandler) {
    this.unregister(this.navigation, handler);
  }

  public registerForErrors(handler: EventHandler) {
    this.register(this.error, handler);
  }

  public unregisterFromErrors(handler: EventHandler) {
    this.unregister(this.error, handler);
  }

  public emitStateChange(data?: any) {
    this.state = data;
    this.emit(this.stateChange, data);
  }

  public emitError(error: Error) {
    this.emit(this.error, error);
  }

  public emitNavigation(url: string) {
    this.emit(this.navigation, url);
  }

  private register(event: string, handler: EventHandler) {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(handler);
  }

  private unregister(event: string, handler: EventHandler) {
    if (!this.events[event]) return;
    this.events[event] = this.events[event].filter((h) => h !== handler);
  }

  private emit(event: string, data?: any) {
    if (!this.events[event]) return;
    this.events[event].forEach((handler) => handler(data));
  }
}

export const eventEmitter = new ConcreteEventEmitter();
