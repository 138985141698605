import IconEmptyDefault from '~/assets/icons/empty.svg';
import IconEmptyBlack from '~/assets/icons/emptyBlack.svg';
import IconEmptyGreen from '~/assets/icons/emptyGreen.svg';
import IconEmptyOrange from '~/assets/icons/emptyOrange.svg';
import IconEmptyPurple from '~/assets/icons/emptyPurple.svg';
import { fullStackNetworkManager } from '~/gateways/network/fullStackNetworkManager';
import { CompanyInfo, CompanyStyle } from '~/types/CompanyInfo';
import { Gateway } from './gateway';
import { MagicRequest, RequestMethod } from './network/magicRequest';

const ACCOUNTS_URL = '/api/companies/url';

enum ThemeColor {
  Purple = 'Purple',
  Orange = 'Orange',
  Green = 'Green',
  Black = 'Black',
}

class CompanyGateway extends Gateway {
  public getCompanyInfo = async (domain: string): Promise<CompanyInfo> => {
    console.log('Getting company info for domain:', domain);
    const request: MagicRequest = {
      url: ACCOUNTS_URL + `?domain=${domain}`,
      method: RequestMethod.GET,
      headers: this.createCommonHeaders(),
    };
    const response: Response = await this.sendRequest(request);
    const json = await response.json();
    return this.createCompanyInfoFromJson(json);
  };

  private createCompanyInfoFromJson(json: any): CompanyInfo {
    return {
      id: json.id,
      name: json.name,
      email: json.email,
      url: json.subUrl,
      language: json.language,
      phoneNumber: json.phoneNumber,
      timeZone: json.timeZoneInfo,
      style: this.createCompanyStyleFromJson(json?.settings?.metadata?.themeColor),
      logo: json.settings?.logoUrl,
      coverUrl: json.settings?.coverUrl,
      customDomain: json.customDomain,
      facebook: json?.settings?.socialLinks?.facebook,
      instagram: json?.settings?.socialLinks?.instagram,
      linkedIn: json?.settings?.socialLinks?.linkedIn,
      twitter: json?.settings?.socialLinks?.twitter,
      metadataTitle: json?.settings?.metadata?.title,
      metadataDescription: json?.settings?.metadata?.description,
      metadataThemeColor: json?.settings?.metadata?.themeColor,
      metadataTitleTemplate: json?.settings?.metadata?.titleTemplate,
      themeEmptyIcon: this.getEmptyIcon(json?.settings?.metadata?.themeColor),
      iconUrl: json?.settings?.iconUrl,
      canReceiveRentalApplications: json?.canReceiveRentalApplications,
    };
  }

  private getEmptyIcon(theme: string): string {
    switch (theme) {
      case ThemeColor.Purple:
        return IconEmptyPurple;
      case ThemeColor.Orange:
        return IconEmptyOrange;
      case ThemeColor.Green:
        return IconEmptyGreen;
      case ThemeColor.Black:
        return IconEmptyBlack;
      default:
        return IconEmptyDefault;
    }
  }

  private createCompanyStyleFromJson(theme: string): CompanyStyle {
    switch (theme) {
      case ThemeColor.Purple:
        return {
          primaryColor: '#9211AF',
          hoverColor: '#AC37C6',
          activeColor: '#7B1192',
          gradientStartColor: '#F3E9F0',
          gradientEndColor: '#B973CB',
        };
      case ThemeColor.Orange:
        return {
          primaryColor: '#A84D19',
          hoverColor: '#C6652D',
          activeColor: '#893B0E',
          gradientStartColor: '#F3EDE9',
          gradientEndColor: '#D4A073',
        };
      case ThemeColor.Green:
        return {
          primaryColor: '#0F995E',
          hoverColor: '#24B476',
          activeColor: '#087546',
          gradientStartColor: '#E9F3EA',
          gradientEndColor: '#B0DFCB',
        };
      case ThemeColor.Black: {
        return {
          primaryColor: '#232231',
          hoverColor: '#4F4E67',
          activeColor: '#0D0C15',
          gradientStartColor: '#F3F3F3',
          gradientEndColor: '#9C9CB3',
        };
      }
      default: //Blue
        return {
          primaryColor: '#0C48BC',
          hoverColor: '#3670E0',
          activeColor: '#073A9E',
          gradientStartColor: '#E7ECF6',
          gradientEndColor: '#A0B8E8',
        };
    }
  }

  public getLanguages = async (): Promise<{ name: string; code: string }[]> => {
    const request: MagicRequest = {
      url: '/api/public/languages',
      method: RequestMethod.GET,
      headers: this.createCommonHeaders(),
    };
    const response: Response = await this.sendRequest(request);
    return await response.json();
  };
}

export const companyGateway = new CompanyGateway(fullStackNetworkManager);
